@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");

html {
  height: 100%;
}
body {
  background: black;
  color: #fff;
  height: 100%;
  margin: 0;
  font-family: "Fira Mono", monospace;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background: linear-gradient(45deg, #000000c9, #00000099),  url(../../assets/img/bg-404.jpg);
}

.content {
  position: relative;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.astro-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 165px;
  font-family: "Fira Mono", monospace;
  letter-spacing: -7px;
  line-height: 0.8;
}


.astro-container {
  
}


.static {
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.error-page:before,
.error-page:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.static:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.static:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}


@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}


.astronaut-wrap {
  position: absolute;
  right: 82%;
  bottom: 51%;
}

.astronaut-one {
  filter: invert(1);
  width: 250px;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}

@media (max-width: 991px) {
  .astronaut-wrap {
    position: relative;
    right: unset;
    bottom: unset;
  }

  .astronaut-one {
    width: 180px;
    animation: float 6s ease-in-out infinite;
  }

  .astro-wrapper {
    font-size: 110px;
  }
}  